WiseMetering.Views.NewDatelkaConfiguration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_configuration',
    template: 'templates/integration_configurations/datelka/new',
    title: () => i18next.t('modal.title.integration_configurations.new'),
    ui: {
        buildings: '#integration_configuration_building_id'
    },

    onShow: function() {
        const
            usedBuildingIds = this.model.license().configurations().pluck('building_id'),
            buildings = WiseMetering.zones.buildings().models.filter(building => !usedBuildingIds.includes(building.id));

        this.ui.buildings.html(WiseMetering.utils.optionsFromModels(buildings));
    }
});
