module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>&nbsp;</div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <dl class=\'stepped zero\'>\n                    <dt>ID</dt>\n                    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n                    <dd><a href="' +
((__t = ( building.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'</a></dd>\n                    <dt>' +
((__t = ( i18next.t('integrations.reference_indicator') )) == null ? '' : __t) +
'</dt>\n                    <dd><a href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a></dd>\n                </dl>\n            </div>\n        </div>\n    </div>\n</div>\n\n\n\n\n\n';

}
return __p
}