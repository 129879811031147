module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.configuration') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( configurationName )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n    <dd><a href="' +
((__t = ( building.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'</a></dd>\n    <dt>' +
((__t = ( i18next.t('integrations.reference_indicator') )) == null ? '' : __t) +
'</dt>\n    <dd><a href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a></dd>\n</dl>\n';

}
return __p
}