WiseMetering.Views.ShowERedesIntegration = WiseMetering.Views.Content.extend({
    icon: 'wiseStore',

    initialize({ model, view }) {
        const partner = model.partner();
        this.name = `${partner?.get('name')} > ${this.model.get('name')}`;

        this.tabs = [
            {
                active: view === 'details',
                href: `/integrations/${model.id}/details`,
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.ERedesIntegrationDetails
            },
            {
                active: view === 'files',
                href: `/integrations/${model.id}/files`,
                id: 'files',
                name: i18next.t('common.files'),
                view: WiseMetering.Views.ERedesIntegrationFiles
            }
        ];
    }
});
