WiseMetering.Model.Integration = WiseMetering.Model.extend({
    urlRoot: '/integrations',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    configuration: function() {
        return WiseMetering.integrationConfigurations.get(this.get('configuration_id'));
    },

    disable: function(opts) {
        const options = {
            contentType: 'application/json',
            type: 'POST',
            url: `${this.url()}/disable`
        };

        _.extend(options, opts);

        return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    fetchData: function(opts) {
        const options = {
            contentType: 'application/json',
            type: 'GET',
            url: `${this.url()}/data`
        };

        _.extend(options, opts);

        return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    indicatorKind: function() {
        return WiseMetering.indicatorKinds.findWhere({ slug: this.get('kind_slug') });
    },

    integrationIndicators: function() {
        return WiseMetering.indicators.where({ integration_id: this.id });
    },

    partner: function() {
        return WiseMetering.integrationPartners.get(this.get('partner_id'));
    }
});
