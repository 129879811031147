WiseMetering.Views.NextbittDataIntegrationsTable = WiseMetering.Views.TableIndex.extend({
    icon: 'integrations',
    name: () => 'nextbitt_data',

    createTable: function() {
        this.collection = this.model.integrations();

        this.table = new WiseMetering.Views.Table({
            id: 'integrations',
            collection: this.collection,
            disableSelect: !WiseMetering.currentUser.isSuperUser(),
            modelSerializer: model => {
                let
                    color = 'orange',
                    state = model.get('state');

                if (state === 'active') {
                    color = 'green';
                }

                return {
                    buildingName: model.building().get('name'),
                    configurationName: model.configuration().get('name'),
                    id: model.id,
                    indicatorKind: model.indicatorKind().get('name'),
                    name: model.get('name'),
                    ratio: model.get('ratio'),
                    reference: model.get('reference'),
                    updatedAt: WiseMetering.utils.formatDate(model.get('updated_at')),
                    state: { color, state }
                };
            },
            columns: {
                state: {
                    mRender: ({ color, state }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: state.capitalize()
                    }),
                    sWidth: '20px'
                },
                configurationName: i18next.t('glossary.configuration'),
                name: i18next.t('common.name'),
                buildingName: i18next.t('glossary.building'),
                indicatorKind: i18next.t('glossary.indicator_kind'),
                ratio: i18next.t('glossary.ratio'),
                reference: i18next.t('integrations.reference'),
                updatedAt: i18next.t('glossary.last_change')
            },
            toolbar_actions: {
                new: {
                    label: i18next.t('ui.actions.new'),
                    permission: 'superuser',
                    view_call: () => new WiseMetering.Views.NewNextbittDataIntegration({
                        collection: WiseMetering.integrations,
                        license: this.model,
                        model: new WiseMetering.Model.Integration({ license_id: this.model.id })
                    })
                },
                edit: {
                    label: i18next.t('ui.actions.edit'),
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.EditNextbittDataIntegration({ model })
                },
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => new WiseMetering.Views.ShowNextbittDataIntegration({ model })
                },
                disable: {
                    icon: 'disable',
                    label: i18next.t('ui.actions.disable'),
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.DisableIntegration({ model })
                },
                delete: {
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.Modal.DestroyExtraConfirmation({
                        model,
                        title: () => i18next.t('modal.title.integrations.destroy')
                    })
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.integrations);

        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrations, 'remove add change', this.createTable.bind(this));

        this.createTable();
    }
});
