WiseMetering.Views.NewMeasurablIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/measurabl/new',
    ui: {
        configurations: '#integration_configuration_id',
        indicators: '#integration_indicator_id',
        startFetchingAt: '#integration_start_fetching_at'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onRender: function() {
        const maxRetention = WiseMetering.currentOrganization.tier().get('data_retention');

        this.ui.startFetchingAt.datepicker({
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true,
            maxDate: moment().endOf('year').format('YYYY-MM-DD'),
            minDate: moment().add(-maxRetention, 'years').startOf('year').format('YYYY-MM-DD')
        });
    },

    onShow: function() {
        const
            configurations = WiseMetering.utils.optionsFromModels(this.options.license.configurations().models, 'name'),
            indicators = WiseMetering.utils.optionsFromModels(WiseMetering.indicators.utilityIndicators().models);

        this.ui.configurations.html(configurations);
        this.ui.indicators.html(indicators);
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    }
});
