WiseMetering.Views.ShopperTrakIntegrationData = WiseMetering.Views.TableIndex.extend({
    createTable: function(data = []) {
        const collection = new WiseMetering.Collection(data);

        this.table = new WiseMetering.Views.Table({
            disableSelect: true,
            id: 'shopper-trak-data',
            collection,
            modelSerializer: model => {
                return {
                    date: model.get('date'),
                    indicator: model.get('indicator') || '-',
                    integration: model.get('integration') || '-',
                    kpi: model.get('kpi') || '-'
                };
            },
            columns: {
                date: i18next.t('common.date'),
                indicator: `${i18next.t('glossary.indicator')} (${this.model.indicator().get('unit')})`,
                integration: `${i18next.t('glossary.indicator')} (${i18next.t('glossary.visitors')})`,
                kpi: i18next.t('glossary.kpi')
            },
            toolbar_actions: {
                exportData: {
                    icon: 'csv',
                    label: i18next.t('ui.actions.export'),
                    view_call: () => window.open(`${process.env.API_URL}/integrations/${this.model.id}/data.csv`)
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onShow: function() {
        this.showLoader();

        this.model.fetchData().then(function(data) {
            this.createTable(data);
        }.bind(this));
    }
});
